import { animated, useSpring } from "react-spring";

import Container from "../../components/Container";
import FullscreenSlideshow from "../../components/FullscreenSlideshow";
import LinkButton from "../../components/LinkButton";
import LogoText from "../../components/LogoText";
import React from "react";
import SlideImage01 from "../../statics/img/main/1.png";
import SlideImage02 from "../../statics/img/main/2.png";
import SlideImage03 from "../../statics/img/main/3.png";
import SlideImage04 from "../../statics/img/main/4.png";
import SlideImage05 from "../../statics/img/main/5.png";
import StickyFooter from "../../components/StickyFooter/StickyFooter";
import StickyNavbar from "../../components/StickyNavbar";

const Home = () => {
  const animation = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: {
      opacity: 0,
      transform: "translateY(20px)",
    },
  });

  return (
    <div className="HomePage">
      <div className="relative w-full h-full">
        <StickyNavbar />
        <FullscreenSlideshow
          slides={[
            SlideImage01,
            SlideImage02,
            SlideImage03,
            SlideImage04,
            SlideImage05,
          ]}
        >
          <animated.div
            className="absolute top-0 left-0 flex items-end justify-center w-full h-full text-white bg-black bg-opacity-50 lg:items-center"
            style={animation}
          >
            <div className="w-full mb-32 lg:mb-0">
              <Container>
                <div className="space-y-12">
                  <div className="space-y-4">
                    <div className="mx-auto lg:max-w-md">
                      <LogoText />
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-center space-y-4 lg:space-x-4 lg:space-y-0 lg:flex-no-wrap">
                    <LinkButton
                      className="w-full lg:w-auto"
                      to="/properties"
                      style={{
                        minWidth: "200px",
                      }}
                    >
                      Buy Property
                    </LinkButton>
                    <LinkButton
                      className="w-full lg:w-auto"
                      to="/sell"
                      theme="invert"
                      style={{
                        minWidth: "200px",
                      }}
                    >
                      Sell Property
                    </LinkButton>
                  </div>
                </div>
              </Container>
            </div>
          </animated.div>
        </FullscreenSlideshow>
        <StickyFooter />
      </div>
    </div>
  );
};

export default Home;
