import HorizontalImage from "../HorizontalImage";
import Icon from "../Icon";
import { NavLink } from "react-router-dom";
import React from "react";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

export interface PropertyThumbnailProps {
  id: string;
  title: string;
  location: string;
  type: string;
  price: string;
  image: string;
}

const PropertyThumbnail = ({
  id,
  title,
  location,
  price,
  image,
}: PropertyThumbnailProps) => {
  return (
    <NavLink to={`/properties/${id}`}>
      <div className="w-full text-white">
        <HorizontalImage src={image} />
        <div className="py-4 space-y-2">
          <div className="text-xl">{title}</div>
          <div className="text-sm">
            <div className="flex justify-between">
              <div className="inline-block">
                <Icon icon={faMapMarkerAlt} /> {location}
              </div>
              <div className="inline-block font-light">{price}</div>
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default PropertyThumbnail;
