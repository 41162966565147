import { animated, useSpring } from "react-spring";

import BackgroundImage from "../../components/BackgroundImage";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import ImageSrc from "../../statics/img/main/2.png";
import LinkButton from "../../components/LinkButton";
import Navbar from "../../components/Navbar";
import React from "react";
import StickyFooter from "../../components/StickyFooter";
import StickyNavbar from "../../components/StickyNavbar";

const About = () => {
  const animation = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: {
      opacity: 0,
      transform: "translateY(20px)",
    },
  });

  return (
    <div className="AboutPage">
      <div className="relative z-10 hidden lg:block">
        <StickyNavbar />
      </div>
      <div className="relative z-10 lg:hidden">
        <Navbar />
      </div>

      <div className="fixed top-0 bottom-0 left-0 right-0 z-0">
        <BackgroundImage src={ImageSrc} />
      </div>

      <animated.div
        className="flex items-center justify-center py-12 lg:h-screen"
        style={animation}
      >
        <Container>
          <div
            className="flex items-center justify-start w-full"
            style={{
              minHeight: "75vh",
            }}
          >
            <div className="max-w-lg space-y-4 text-white">
              <h1 className="text-4xl font-bold">About Us</h1>
              <div className="space-y-4 font-light leading-relaxed tracking-wide">
                <p className="font-bold">
                  Imagine Land is a property investment company, focuses on land
                  and condominiums based in Bangkok, Thailand.
                </p>
                <p>
                  Started in 2004, we were a property agency for individuals. In
                  2006, we began flipping properties. In 2007, we invested in
                  small lands and allocated them to individual owners. In 2009,
                  we became a property agent for developers. In 2010, we fully
                  became a land investor. Today, we are property consultant and
                  interior design , expertise for anyone investing in property.
                </p>
                <p>
                  Our services include property investment, property
                  consultancy, brokerage, and interior design. Our vision, to
                  become a leading and internationally recognized property
                  investment, promises our clients’ goals, hopes, and dreams to
                  become reality.
                </p>
              </div>
              <div className="space-y-4 sm:space-y-0 sm:space-x-4">
                <LinkButton to="/contact">Contact Us</LinkButton>
                <LinkButton to="/services" theme="white">
                  What We Offer
                </LinkButton>
              </div>
            </div>
          </div>
        </Container>
      </animated.div>

      <div className="hidden lg:block">
        <StickyFooter />
      </div>
      <div className="lg:hidden">
        <Footer />
      </div>
    </div>
  );
};

export default About;
