import { animated, useSpring } from "react-spring";

import BackgroundImage from "../../components/BackgroundImage";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import ImageSrc from "../../statics/img/main/7.png";
import Navbar from "../../components/Navbar";
import React from "react";
import StickyFooter from "../../components/StickyFooter";
import StickyNavbar from "../../components/StickyNavbar";

const Contact = () => {
  const animation = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: {
      opacity: 0,
      transform: "translateY(20px)",
    },
  });

  return (
    <div className="ContactPage">
      <div className="relative z-10 hidden lg:block">
        <StickyNavbar />
      </div>
      <div className="relative z-10 lg:hidden">
        <Navbar />
      </div>

      <div className="fixed top-0 bottom-0 left-0 right-0 z-0">
        <BackgroundImage src={ImageSrc} />
      </div>

      <animated.div
        className="flex items-center justify-center h-full text-white"
        style={animation}
      >
        <Container>
          <div className="flex items-center justify-end h-screen">
            <div className="md:max-w-md">
              <h1 className="mb-4 text-xl font-light tracking-wide">
                Kittitath Jenhatakarnkij
              </h1>
              <div className="text-sm font-light tracking-wide">
                <a href="mailto:kittitath@imagine-land.com" className="block">
                  kittitath@imagine-land.com
                </a>
                <a href="tel:(+66) 84 847 5553" className="block">
                  (+66) 84 847 5553
                </a>
              </div>
            </div>
          </div>
        </Container>
      </animated.div>

      <div className="hidden lg:block">
        <StickyFooter />
      </div>
      <div className="lg:hidden">
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
