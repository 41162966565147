import React, { ChangeEvent, SelectHTMLAttributes } from "react";

import Icon from "../Icon";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export interface SelectInputProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  value: string | number;
  options: {
    text: string;
    value?: string | number;
  }[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const SelectInput = ({
  value,
  options,
  onChange,
  ...props
}: SelectInputProps) => {
  return (
    <div className="relative w-full">
      <div className="absolute right-0">
        <Icon icon={faAngleDown} />
      </div>
      <label>
        <select
          className="w-full bg-transparent border-b rounded-none outline-none appearance-none"
          value={value}
          onChange={onChange}
          {...props}
        >
          <option disabled>Select option</option>
          {options.map((option, key) => (
            <option value={option.value} key={key}>
              {option.text}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default SelectInput;
