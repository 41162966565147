import "mapbox-gl/dist/mapbox-gl.css";

import React, { createRef, useEffect } from "react";
import ReactDOM from "react-dom";

import { PropertyProps } from "../../hooks/useFetchProperties";
import mapboxgl from "mapbox-gl";
import { useParams } from "react-router-dom";
import Icon from "../Icon";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

mapboxgl.accessToken =
  "pk.eyJ1IjoicG9vbWllcG9vbSIsImEiOiI0MjVmNTQxNzY4MDZiNDdlMWQ3ZGRiNmUxMjFkNmZjOCJ9.rf96bGij54UcT64-So-GfA";

export interface MapCanvasProps {
  properties?: PropertyProps[];
}

const MapCanvas = ({ properties, ...props }: MapCanvasProps) => {
  const { propertyID } = useParams<{
    propertyID?: string;
  }>();

  const mapContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const lat = 13.7563;
    const lng = 100.5018;
    const zoom = 10;

    if (mapContainer.current) {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/poomiepoom/cipwt978v004udnncjnfstskw",
        center: [lng, lat],
        zoom: zoom,
      });

      const geojson = {
        type: "FeatureCollection",
        features: properties?.map((property) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: property.coordinate,
            },
            properties: {
              title: property.title,
              description: property.description,
              image: property.image,
              link: `/properties/${property.id}`,
            },
          };
        }),
      };

      // On map load
      map.on("load", () => {
        geojson.features?.forEach((marker) => {
          const markerEl = document.createElement("div");
          ReactDOM.render(
            <div className="relative p-1 bg-white border rounded cursor-pointer">
              <div className="absolute bottom-0 left-0 right-0 z-0 flex justify-center transform translate-y-full">
                <div
                  className="transform rotate-45 -translate-y-2 bg-white rounded"
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                ></div>
              </div>
              <div
                className="relative z-10 p-10 bg-black bg-center bg-no-repeat bg-cover"
                style={{
                  backgroundImage: `url(${marker.properties.image})`,
                }}
              ></div>
            </div>,
            markerEl
          );

          // Add popup
          const popupEl = document.createElement("div");
          ReactDOM.render(
            <div
              className="relative p-4 space-y-2"
              style={{
                minWidth: "200px",
              }}
            >
              <div className="absolute top-0 right-0 p-0.5">
                <button
                  type="button"
                  className="focus:outline-none"
                  onClick={() => {
                    popup.remove();
                    map.flyTo({
                      center: [lng, lat],
                      zoom,
                    });
                  }}
                >
                  <Icon icon={faTimes} />
                </button>
              </div>
              <h3 className="text-lg font-bold">{marker.properties.title}</h3>
              <p>{marker.properties.description}</p>
              <a
                href={marker.properties.link}
                className="inline-block underline outline-none text-md"
              >
                View Properties
              </a>
            </div>,
            popupEl
          );

          const popup = new mapboxgl.Popup({
            offset: 50,
            maxWidth: "",
            closeButton: false,
          }).setDOMContent(popupEl);

          // Handle popup open
          popup.on("open", () => {
            map.flyTo({
              center: marker.geometry.coordinates,
              zoom: 14,
            });
          });

          // Add marker to map
          new mapboxgl.Marker(markerEl)
            .setLngLat(marker.geometry.coordinates)
            .setPopup(popup)
            .addTo(map);
        });

        // Fly to property if specified
        if (propertyID && properties && properties.length) {
          const property = properties?.find(
            (property) => property.id === propertyID
          );

          if (property) {
            map.flyTo({
              center: [property.lng, property.lat],
              zoom: 13,
            });
          }
        }
      });
    }
  }, [mapContainer, properties, propertyID]);

  return <div className="w-full h-full" ref={mapContainer}></div>;
};

export default MapCanvas;
