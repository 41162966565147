import Container from "../Container";
import Logo from "../Logo";

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { animated, useSpring } from "react-spring";

import MobileNav from "../MobileNav";
import DesktopNav from "../DesktopNav";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const bgAnimation = useSpring({
    backgroundColor: toggle ? "rgba(0, 0, 0, 0.9)" : "rgba(0, 0, 0, 0.5)",
  });

  const dropdownAnimation = useSpring({
    height: toggle ? "100vh" : "0vh",
    opacity: toggle ? "100%" : "0%",
  });

  return (
    <animated.div
      className="w-full max-h-screen text-white bg-black"
      style={bgAnimation}
    >
      <Container>
        <div className="flex items-center justify-between py-4">
          <button
            type="button"
            className="w-16 text-sm font-light text-left uppercase transition-opacity duration-300 ease-in hover:opacity-50 md:hidden focus:outline-none"
            onClick={() => setToggle(!toggle)}
          >
            {toggle ? "Close" : "Menu"}
          </button>

          <NavLink to="/">
            <div className="w-12 md:w-20">
              <Logo />
            </div>
          </NavLink>

          <NavLink
            to="/contact"
            className="w-16 text-sm font-light text-right uppercase transition-opacity duration-300 ease-in hover:opacity-50 md:hidden focus:outline-none"
          >
            Contact
          </NavLink>

          <div className="hidden md:block">
            <DesktopNav />
          </div>
        </div>

        <animated.div
          className="relative md:hidden"
          style={{
            ...dropdownAnimation,
          }}
        >
          <div
            className="absolute top-0 left-0 w-full py-4 overflow-y-auto"
            style={{
              height: "80%",
            }}
          >
            <MobileNav />
          </div>
        </animated.div>
      </Container>
    </animated.div>
  );
};

export default Navbar;
