import Container from "../../components/Container";
import Navbar from "../../components/Navbar";
import PropertyFilters from "../../components/PropertyFilters";
import PropertyListing from "../../components/PropertyListing";
import React from "react";
import ViewSwitcher from "../../components/ViewSwitcher";

const Properties = () => {
  return (
    <div className="PropertiesPage">
      <div className="absolute top-0 left-0 w-full h-full overflow-y-scroll text-white bg-black">
        <Navbar />
        <Container>
          <div className="my-12 space-y-12">
            <div className="space-y-2">
              <h1 className="text-5xl">Our Properties</h1>
              <div className="relative">
                <div className="max-w-md">
                  <PropertyFilters />
                </div>
                <div className="absolute top-0 right-0 hidden md:block">
                  <ViewSwitcher />
                </div>
              </div>
            </div>
            <PropertyListing />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Properties;
