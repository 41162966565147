import { useMemo } from "react";

export type ButtonTheme = "default" | "invert" | "outline" | "white";

const useButtonTheme = (theme: ButtonTheme = "default") => {
  const selectedTheme = useMemo(() => {
    const themes = {
      default:
        "bg-black text-white border-black hover:bg-black hover:bg-opacity-50",
      invert:
        "bg-transparent text-white border-black hover:bg-black hover:bg-opacity-50",
      outline:
        "bg-transparent text-black border-black hover:bg-black hover:bg-opacity-50 hover:text-white",
      white:
        "bg-transparent text-white border-white hover:bg-white hover:bg-opacity-50 hover:text-black",
    };

    return themes[theme];
  }, [theme]);

  return selectedTheme;
};

export default useButtonTheme;
