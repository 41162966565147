import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";

import Icon from "../Icon";
import NavGroup from "../NavGroup";
import React from "react";

const MobileNav = () => {
  const groups = [
    [
      {
        to: "/about",
        text: "About Us",
      },
      {
        to: "/properties",
        text: "Properties",
      },
      {
        to: "tel:+66848475553",
        text: (
          <>
            <Icon icon={faPhone} /> Call
          </>
        ),
      },
      {
        to: "/map",
        text: (
          <>
            <Icon icon={faMapMarkerAlt} /> Map
          </>
        ),
      },
    ],
    [
      {
        to: "/services",
        text: "Our Services",
      },
    ],
    [
      {
        to: "/properties",
        text: "Buy Property",
      },
      {
        to: "/sell",
        text: "Sell Property",
      },
    ],
  ];

  return <NavGroup groups={groups} />;
};

export default MobileNav;
