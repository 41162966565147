import React, { useContext, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import Filter from "../Filter";
import FiltersContext from "../../contexts/FiltersContext";
import Icon from "../Icon";
import PropertiesContext from "../../contexts/PropertiesContext";
import { uniq } from "lodash";

const PropertyFilters = () => {
  const [toggle, setToggle] = useState(false);
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [tier, setTier] = useState(0);
  const isReset = location === "" && type === "" && tier === 0;

  // Get properties
  const properties = useContext(PropertiesContext);
  const locations = uniq(
    properties.map((property: any) => property.location)
  ).map((location) => {
    return {
      text: location,
      value: location,
    };
  });

  // Get filters
  const [filters, setFilters] = useContext(FiltersContext);
  useEffect(() => {
    setFilters({ location, type, tier });
  }, [setFilters, location, type, tier]);

  // Reset filters
  const resetFilters = () => {
    setLocation("");
    setType("");
    setTier(0);
  };

  // Animation
  const animation = useSpring({
    opacity: toggle ? "1" : "0",
    transform: `translateY(${toggle ? "0px" : "-10px"})`,
  });

  return (
    <div className="w-full space-y-8">
      <button type="button" onClick={() => setToggle(!toggle)}>
        Filters <Icon icon={toggle ? faAngleDown : faAngleRight} />
      </button>
      <animated.div
        className="space-y-4"
        style={{
          ...animation,
          display: animation.opacity.interpolate((o) =>
            o === "0" ? "none" : "block"
          ),
        }}
      >
        <Filter
          field="Location"
          value={filters.location}
          onChange={(e) => setLocation(e.target.value)}
          options={[{ text: "Any", value: "" }, ...locations]}
        />
        <Filter
          field="Type"
          value={filters.type}
          onChange={(e) => setType(e.target.value)}
          options={[
            { text: "Any", value: "" },
            { text: "Land", value: "Land" },
            { text: "Condominium", value: "Condominium" },
            { text: "House", value: "House" },
            { text: "Commercial Building", value: "Commercial Building" },
            { text: "Hostel", value: "Hostel" },
            { text: "Other", value: "Other" },
          ]}
        />
        <Filter
          field="Price"
          value={filters.tier}
          onChange={(e) => setTier(parseInt(e.target.value))}
          options={[
            { text: "Any", value: 0 },
            { text: "less than 5,000,000", value: 1 },
            { text: "5,000,001 - 10,000,000", value: 2 },
            { text: "10,000,001 - 15,000,000", value: 3 },
            { text: "15,000,001 - 20,000,000", value: 4 },
            { text: "20,000,001 - 25,000,000", value: 5 },
            { text: "25,000,001 - 30,000,000", value: 6 },
            { text: "more thant 30,000,000", value: 7 },
          ]}
        />
        <button
          type="button"
          className={`px-6 py-2 text-sm border rounded ${
            isReset ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={() => resetFilters()}
          disabled={isReset}
        >
          Reset
        </button>
      </animated.div>
    </div>
  );
};

export default PropertyFilters;
