import Container from "../Container";
import Copyright from "../Copyright";
import React from "react";
import SocialNav from "../SocialNav";

const Footer = () => {
  return (
    <Container>
      <div className="flex justify-between py-4">
        <SocialNav />
        <Copyright />
      </div>
    </Container>
  );
};

export default Footer;
