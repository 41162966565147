import { animated, useSpring } from "react-spring";
import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";

import BackgroundImage from "../../components/BackgroundImage";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import Icon from "../../components/Icon";
import LinkButton from "../../components/LinkButton";
import Navbar from "../../components/Navbar";
import React from "react";
import SampleImage from "../../statics/img/main/1.png";
import StickyFooter from "../../components/StickyFooter";
import StickyNavbar from "../../components/StickyNavbar";
import useFetchProperty from "../../hooks/useFetchProperty";
import { useParams } from "react-router-dom";

const Property = () => {
  const { propertyID } = useParams<{ propertyID: string }>();
  const property = useFetchProperty(propertyID);
  const { title, location, area, price, description, image } = property || {};
  const animation = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: {
      opacity: 0,
      transform: "translateY(20px)",
    },
  });

  return (
    <div className="PropertyPage">
      <div className="relative z-10 hidden lg:block">
        <StickyNavbar />
      </div>
      <div className="relative z-10 lg:hidden">
        <Navbar />
      </div>

      <div className="fixed top-0 bottom-0 left-0 right-0 z-0">
        <BackgroundImage src={image || SampleImage} />
      </div>

      <animated.div
        className="flex flex-wrap justify-between h-screen py-12 text-white lg:items-center"
        style={animation}
      >
        <Container>
          <div className="flex items-start justify-between">
            <div className="flex items-center justify-between">
              <div className="w-12 h-px bg-white lg:mr-4"></div> {location}
            </div>

            <div className="space-y-2 text-right">
              <p>{price}</p>
              <p>{area}</p>
              <div className="flex justify-end space-x-8">
                <a href="tel:(+66) 84 847 5553">
                  <Icon icon={faPhone} />
                </a>

                <a href={`/map/${propertyID}`}>
                  <Icon icon={faMapMarkerAlt} />
                </a>
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className="space-y-8">
            <div className="space-y-2">
              <h1 className="text-5xl">{title}</h1>
              <p className="text-xl font-light">{description}</p>
            </div>
            <div className="space-y-4 sm:space-y-0 sm:space-x-4">
              <LinkButton to="/contact">Contact Us</LinkButton>
              <LinkButton to="/properties" theme="invert">
                See Other Properties
              </LinkButton>
            </div>
          </div>
        </Container>
      </animated.div>

      <div className="hidden lg:block">
        <StickyFooter />
      </div>
      <div className="lg:hidden">
        <Footer />
      </div>
    </div>
  );
};

export default Property;
