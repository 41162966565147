import React from "react";
import Slideshow from "../Slideshow";
import { SlideshowProps } from "../Slideshow/Slideshow";

export interface FullscreenSlideshowProps extends SlideshowProps {}

const FullscreenSlideshow = (props: FullscreenSlideshowProps) => {
  return (
    <div className="relative w-screen h-screen">
      <div className="absolute top-0 left-0 z-0 w-screen h-screen bg-black">
        <Slideshow {...props} />
      </div>
    </div>
  );
};

export default FullscreenSlideshow;
