import { useEffect, useState } from "react";
import useFetchProperties, { PropertyProps } from "./useFetchProperties";

const useFetchProperty = (propertyID: string) => {
  const properties = useFetchProperties();
  const [property, setProperty] = useState<PropertyProps>();

  useEffect(() => {
    const property = properties?.find((property) => property.id === propertyID);

    if (property) {
      setProperty(property);
    }
  }, [properties, propertyID]);

  return property;
};

export default useFetchProperty;
