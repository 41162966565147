import Container from "../../components/Container";
import Navbar from "../../components/Navbar";
import React from "react";
import SellPropertyForm from "../../components/SellPropertyForm";

const SellProperty = () => {
  return (
    <div className="SellPropertyPage">
      <div className="absolute top-0 left-0 w-full h-full overflow-y-scroll text-white bg-black">
        <Navbar />
        <Container>
          <div className="my-12 space-y-12">
            <div className="space-y-2">
              <h1 className="text-5xl">Sell Property</h1>
            </div>
            <div className="flex flex-wrap space-y-8 lg:space-y-0 lg:space-x-8 lg:flex-no-wrap">
              <div className="w-full lg:w-8/12">
                <SellPropertyForm />
              </div>
              <div className="w-full lg:w-4/12">
                <h1 className="text-xl font-light tracking-wide">
                  Kittitath Jenhatakarnkij
                </h1>
                <div className="text-sm font-light tracking-wide">
                  <a href="mailto:kittitath@imagine-land.com" className="block">
                    kittitath@imagine-land.com
                  </a>
                  <a href="tel:(+66) 84 847 5553" className="block">
                    (+66) 84 847 5553
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SellProperty;
