import React, { ButtonHTMLAttributes, ReactNode } from "react";

import useButtonTheme from "../../hooks/useButtonTheme";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  disabled?: boolean;
  theme?: "default" | "invert" | "outline" | "white";
}

const Button = ({
  className,
  children,
  disabled,
  theme = "default",
  style = {
    minWidth: "170px",
  },
  ...props
}: ButtonProps) => {
  const selectedTheme = useButtonTheme(theme);

  return (
    <button
      className={`inline-block px-6 py-3 text-xs font-light tracking-widest text-center uppercase transition-all duration-300 border ${selectedTheme}`}
      style={style}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
