import React, { useContext } from "react";
import { faTh, faThList } from "@fortawesome/free-solid-svg-icons";

import Icon from "../Icon";
import ViewContext from "../../contexts/ViewContext";

const ViewSwitcher = () => {
  const [isThumbnailView, setIsThumbnailView] = useContext(ViewContext);

  return (
    <div className="flex space-x-4">
      <button
        type="button"
        className={
          "transition-opacity duration-300 ease-in " +
          (isThumbnailView ? "opacity-100" : "opacity-50")
        }
        onClick={() => setIsThumbnailView(true)}
      >
        <Icon icon={faTh} />
      </button>
      <button
        type="button"
        className={
          "transition-opacity duration-300 ease-in " +
          (!isThumbnailView ? "opacity-100" : "opacity-50")
        }
        onClick={() => setIsThumbnailView(false)}
      >
        <Icon icon={faThList} />
      </button>
    </div>
  );
};

export default ViewSwitcher;
