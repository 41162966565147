import { animated, useSpring } from "react-spring";

import BackgroundImage from "../../components/BackgroundImage";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import ImageSrc from "../../statics/img/main/4.png";
import LinkButton from "../../components/LinkButton";
import Navbar from "../../components/Navbar";
import React from "react";
import StickyFooter from "../../components/StickyFooter";
import StickyNavbar from "../../components/StickyNavbar";

const Services = () => {
  const animation = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: {
      opacity: 0,
      transform: "translateY(20px)",
    },
  });

  return (
    <div className="ServicesPage">
      <div className="relative z-10 hidden lg:block">
        <StickyNavbar />
      </div>
      <div className="relative z-10 lg:hidden">
        <Navbar />
      </div>

      <div className="fixed top-0 bottom-0 left-0 right-0 z-0">
        <BackgroundImage src={ImageSrc} />
      </div>

      <animated.div
        className="flex items-center justify-center py-12 lg:h-screen"
        style={animation}
      >
        <Container>
          <div
            className="flex items-center justify-start w-full"
            style={{
              minHeight: "75vh",
            }}
          >
            <div className="max-w-lg space-y-4 text-white">
              <h1 className="text-4xl font-bold">Our Services</h1>
              <div className="space-y-4 font-light leading-relaxed tracking-wide">
                <p className="text-xl font-bold">
                  Property Investment Property Consultancy Brokerage Interior
                  Design & Construction
                </p>
                <p>
                  Choosing the right properties to invest is not a simple
                  process. Many residential projects are growing and their
                  prices are increasing. Oversupply in property makes decision
                  making a challenge.
                </p>
                <p>
                  That’s where we come in. We are able to recommend the best
                  properties to suit your needs by conducting business
                  researches, which include analytical of land and property, and
                  first-hand survey of the real location. We take advantage of
                  real estate networks that we had established throughout our
                  decades of experience. Our large connections allow us to match
                  buyers and sellers appropriately. We can provide you related
                  and useful documentations to maximize the profits.
                </p>
                <p className="font-bold">
                  Professionally, we will help our clients with every step from
                  start to finish.
                </p>
                <p>
                  With our help, we able to find you both interior designer and
                  contractor that can fulfil your style, and design your
                  property on time and on demand.
                </p>
              </div>
              <div className="space-x-4">
                <LinkButton to="/contact" theme="white">
                  Contact Us
                </LinkButton>
              </div>
            </div>
          </div>
        </Container>
      </animated.div>

      <div className="hidden lg:block">
        <StickyFooter />
      </div>
      <div className="lg:hidden">
        <Footer />
      </div>
    </div>
  );
};

export default Services;
