import React from "react";

const Copyright = () => {
  return (
    <div className="text-xs font-light tracking-widest text-white uppercase opacity-75">
      {"©"} IMAGINE LAND {new Date().getFullYear()}
    </div>
  );
};

export default Copyright;
