import { useEffect, useState } from "react";

import data from "../statics/data/properties.json";

export interface PropertyProps {
  id: string;
  title: string;
  price: string;
  tier: number;
  area: string;
  type: string;
  description: string;
  image: string;
  location: string;
  lat: number;
  lng: number;
  coordinate: [number, number];
  sold: boolean;
}

const useFetchProperties = () => {
  const [properties, setProperties] = useState<PropertyProps[]>();

  useEffect(() => {
    setProperties(
      data.map((property) => {
        return {
          ...property,
          image: `/img/${property.image}`,
          coordinate: [property.lng, property.lat],
        };
      })
    );
  }, []);

  return properties;
};

export default useFetchProperties;
