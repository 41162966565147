import React from "react";
import SelectInput from "../SelectInput";
import { SelectInputProps } from "../SelectInput/SelectInput";

export interface FilterProps extends SelectInputProps {
  field: string;
}

const Filter = ({ field, ...props }: FilterProps) => {
  return (
    <div className="flex space-x-2 text-md">
      <div className="font-light">{field}:</div>
      <SelectInput {...props} />
    </div>
  );
};

export default Filter;
