import { Formik } from "formik";
import React, { useState } from "react";
import TextField from "../TextField";
import isEmail from "validator/lib/isEmail";
import SelectInput from "../SelectInput";
import ThailandProvinces from "../../statics/data/thailand-province.json";
import Textarea from "../Textarea";
import Icon from "../Icon";
import { faCheck, faUpload } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import Axios from "axios";
import { Redirect } from "react-router-dom";

const SellPropertyForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isRedirected, setIsRedirected] = useState(false);

  return (
    <Formik
      initialValues={{
        email: "",
        firstname: "",
        lastname: "",
        tel: "",
        type: "",
        province: "",
        saleType: "",
        size: "",
        price: "",
        location: "",
        description: "",
        image: "",
      }}
      validate={(values) => {
        const errors: {
          email?: string;
          firstname?: string;
          lastname?: string;
          tel?: string;
          type?: string;
          province?: string;
          saleType?: string;
          size?: string;
          price?: string;
          location?: string;
          description?: string;
          image?: string;
        } = {};

        if (!values.email) {
          errors.email = "Required";
        } else if (!isEmail(values.email)) {
          errors.email = "Invalid email address";
        }

        if (!values.firstname) {
          errors.firstname = "Required";
        }

        if (!values.lastname) {
          errors.firstname = "Required";
        }

        if (!values.tel) {
          errors.tel = "Required";
        }

        if (!values.province) {
          errors.province = "Required";
        }

        if (!values.saleType) {
          errors.saleType = "Required";
        }

        if (!values.size) {
          errors.size = "Required";
        }

        if (!values.price) {
          errors.price = "Required";
        }

        if (!values.location) {
          errors.location = "Required";
        }

        if (!values.description) {
          errors.description = "Required";
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          Axios.post("https://formspree.io/f/xeqplkwe", values);
          setSubmitting(false);
          setIsSubmitted(true);

          setTimeout(() => {
            setIsRedirected(true);
          }, 1000);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form className="space-y-8" onSubmit={handleSubmit}>
          <div className="w-full">
            <div className="text-sm">Email</div>
            <TextField
              type="email"
              name="email"
              placeholder="email@address.com"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email}
              required
            />
          </div>

          <div className="flex space-x-4">
            <div className="w-full">
              <div className="text-sm">First Name</div>
              <TextField
                type="text"
                name="firstname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstname}
                error={errors.firstname}
                required
              />
            </div>
            <div className="w-full">
              <div className="text-sm">Last Name</div>
              <TextField
                type="text"
                name="lastname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastname}
                error={errors.lastname}
                required
              />
            </div>
          </div>

          <div className="w-full">
            <div className="text-sm">Tel.</div>
            <TextField
              type="text"
              name="tel"
              placeholder="+66 (0) 00 000 0000"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.tel}
              error={errors.tel}
              required
            />
          </div>

          <div className="w-full">
            <div className="text-sm">Type</div>
            <SelectInput
              name="type"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.type}
              options={[
                { text: "Land", value: "Land" },
                { text: "Condominium", value: "Condominium" },
                { text: "House", value: "House" },
                { text: "Commercial Building", value: "Commercial Building" },
                { text: "Hostel", value: "Hostel" },
                { text: "Other", value: "Other" },
              ]}
              required
            />
          </div>

          <div className="w-full">
            <div className="text-sm">Province</div>
            <SelectInput
              name="province"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.province}
              options={ThailandProvinces.map((province) => {
                return {
                  text: province,
                  value: province,
                };
              })}
              required
            />
          </div>

          <div className="w-full">
            <div className="text-sm">Type of Sale</div>
            <SelectInput
              name="saleType"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.saleType}
              options={[
                { text: "Sell", value: "Sell" },
                {
                  text: "Sell with right of redemption",
                  value: "Sell with right of redemption",
                },
              ]}
              required
            />
          </div>

          <div className="flex space-x-4">
            <div className="w-full">
              <div className="text-sm">Size</div>
              <TextField
                type="text"
                name="size"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.size}
                error={errors.size}
                required
              />
            </div>

            <div className="w-full">
              <div className="text-sm">Price</div>
              <TextField
                type="text"
                name="price"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.price}
                error={errors.price}
                required
              />
            </div>
          </div>

          <div className="w-full">
            <div className="text-sm">Location</div>
            <Textarea
              name="location"
              placeholder="Property's address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.location}
              error={errors.location}
              required
            />
          </div>

          <div className="w-full">
            <div className="text-sm">Description</div>
            <Textarea
              name="description"
              placeholder="Describe your property"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              error={errors.description}
              required
            />
          </div>

          <div className="w-full">
            <div className="text-sm">Attach Image</div>
            <TextField
              type="text"
              name="image"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.image}
              error={errors.image}
            />
            <div className="mt-2">
              <a
                href="http://m.imgur.com/upload"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon={faUpload} />{" "}
                <span className="underline">Upload Image</span>
              </a>{" "}
              <span className="text-gray-400">(powered by Imgur)</span>
            </div>
          </div>

          <div className="w-full">
            <Button type="submit" theme="white">
              Submit
            </Button>
          </div>

          {isSubmitted ? (
            <div className="text-green-500">
              <Icon icon={faCheck} /> Submitted successfully.
            </div>
          ) : null}

          {isRedirected ? <Redirect to="/sell" /> : null}
        </form>
      )}
    </Formik>
  );
};

export default SellPropertyForm;
