import React from "react";
import Navbar from "../Navbar";

const StickyNavbar = () => {
  return (
    <div className="absolute top-0 left-0 z-10 w-full overflow-hidden">
      <Navbar />
    </div>
  );
};

export default StickyNavbar;
