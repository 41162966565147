import React, { useContext, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

import GridBackground from "../../components/GridBackground";
import MapCanvas from "../../components/MapCanvas";
import PropertiesContext from "../../contexts/PropertiesContext";
import { PropertyProps } from "../../hooks/useFetchProperties";
import SelectInput from "../../components/SelectInput";
import StickyNavbar from "../../components/StickyNavbar";
import { uniq } from "lodash";

const Map = () => {
  const properties = useContext(PropertiesContext);
  const [isActive, setIsActive] = useState(false);
  const [location, setLocation] = useState("");
  const [locations, setLocations] = useState<{ text: string; value: string }[]>(
    []
  );
  const [results, setResults] = useState<PropertyProps[]>([]);

  // List locations
  useEffect(() => {
    const locations = uniq(
      properties.map((property: PropertyProps) => property.location)
    ).map((location) => {
      return {
        text: location,
        value: location,
      };
    });

    setLocations(locations);
  }, [properties]);

  // Filter properties
  useEffect(() => {
    const results = properties.filter((property: PropertyProps) =>
      location
        ? property.location.toLowerCase() === location.toLowerCase()
        : true
    );

    setResults(results);
  }, [location, properties]);

  // Animation
  const overlayAnimation = useSpring({
    opacity: isActive ? "0" : "1",
  });

  return (
    <div className="MapPage">
      <div className="absolute top-0 left-0 z-30 w-full">
        <StickyNavbar />
      </div>

      <div className="absolute w-full h-full">
        <animated.div
          className="absolute z-10 w-full h-full"
          style={{
            ...overlayAnimation,
            display: overlayAnimation.opacity.interpolate((o) =>
              o === "0" ? "none" : "block"
            ),
          }}
        >
          <GridBackground />

          <div
            className="absolute space-y-4 text-white"
            style={{
              top: "50vh",
              left: "70vw",
              right: "10vw",
            }}
          >
            <div className="text-2xl">Locations</div>
            <SelectInput
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              options={[{ text: "Any", value: "" }, ...locations]}
            />
            <div className="text-sm">
              {results.length} {results.length > 1 ? "locations" : "location"}
            </div>
          </div>
        </animated.div>

        <div
          className="absolute z-10 text-white"
          style={{
            top: "80vh",
            left: "70vw",
            right: "10vw",
          }}
        >
          <div className="inline-block border-b">
            <button
              type="button"
              className="inline-block pb-px text-sm"
              onClick={() => setIsActive(!isActive)}
            >
              {isActive ? "Close Map" : "View Map"}
            </button>
          </div>
        </div>

        <div className="absolute top-0 left-0 z-0 w-full h-full bg-black">
          <MapCanvas properties={results} />
        </div>
      </div>
    </div>
  );
};

export default Map;
