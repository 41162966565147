import "./statics/css/tailwind.output.css";

import React, { useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import FiltersContext from "./contexts/FiltersContext";
import HomePage from "./pages/Home";
import MapPage from "./pages/Map";
import PropertiesContext from "./contexts/PropertiesContext";
import PropertiesPage from "./pages/Properties";
import PropertyPage from "./pages/Property";
import SellPropertyPage from "./pages/SellProperty";
import ServicesPage from "./pages/Services";
import ViewContext from "./contexts/ViewContext";
import useFetchProperties from "./hooks/useFetchProperties";

const App = () => {
  const properties = useFetchProperties();
  const [isThumbnailView, setIsThumbnailView] = useState(true);
  const [filters, setFilters] = useState<any>({});

  return (
    <PropertiesContext.Provider value={properties || []}>
      <ViewContext.Provider value={[isThumbnailView, setIsThumbnailView]}>
        <FiltersContext.Provider value={[filters, setFilters]}>
          <Router>
            <Switch>
              <Route path="/" exact>
                <HomePage />
              </Route>
              <Route path="/about">
                <AboutPage />
              </Route>
              <Route path="/services">
                <ServicesPage />
              </Route>
              <Route path="/contact">
                <ContactPage />
              </Route>
              <Route path="/properties/:propertyID">
                <PropertyPage />
              </Route>
              <Route path="/properties">
                <PropertiesPage />
              </Route>
              <Route path="/sell">
                <SellPropertyPage />
              </Route>
              <Route path="/map/:propertyID?">
                <MapPage />
              </Route>
            </Switch>
          </Router>
        </FiltersContext.Provider>
      </ViewContext.Provider>
    </PropertiesContext.Provider>
  );
};

export default App;
