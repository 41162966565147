import React, { useContext } from "react";
import { animated, useTrail } from "react-spring";

import FiltersContext from "../../contexts/FiltersContext";
import PropertiesContext from "../../contexts/PropertiesContext";
import { PropertyProps } from "../../hooks/useFetchProperties";
import PropertyThumbnail from "../PropertyThumbnail";
import ViewContext from "../../contexts/ViewContext";

const PropertyListing = () => {
  const properties = useContext(PropertiesContext);
  const [isThumbnailView] = useContext(ViewContext);
  const [filters] = useContext(FiltersContext);
  const { location, type, tier } = filters;
  const results = properties
    .filter((property: PropertyProps) =>
      location
        ? property.location.toLowerCase() === location.toLowerCase()
        : true
    )
    .filter((property: PropertyProps) =>
      type ? property.type.toLowerCase() === type.toLowerCase() : true
    )
    .filter((property: PropertyProps) =>
      tier ? property.tier.toString() === tier.toString() : true
    );

  // Animation
  const animation = useTrail(properties?.length || 0, {
    opacity: 1,
    transform: "translateY(0px)",
    from: {
      opacity: 0,
      transform: "translateY(10px)",
    },
  });

  return (
    <div className="space-y-12">
      {results.length ? (
        <div
          className={`grid gap-4 md:grid-cols-${
            isThumbnailView ? "2" : "1"
          } lg:grid-cols-${isThumbnailView ? "3" : "1"}`}
        >
          {results.map((result: any, key: any) => (
            <animated.div className="w-full" key={key} style={animation[key]}>
              <PropertyThumbnail {...result} />
            </animated.div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full p-4 text-xs text-white text-opacity-50 uppercase border border-white border-opacity-25">
          No items to display
        </div>
      )}
    </div>
  );
};

export default PropertyListing;
