import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";

import Icon from "../Icon";
import NavGroup from "../NavGroup";
import React from "react";

const DesktopNav = () => {
  const groups = [
    [
      {
        to: "/about",
        text: "About Us",
      },
      {
        to: "/properties",
        text: "Properties",
      },
      {
        to: "tel:+66848475553",
        text: <Icon icon={faPhone} />,
      },
      {
        to: "/map",
        text: <Icon icon={faMapMarkerAlt} />,
      },
      {
        to: "/services",
        text: "Our Services",
      },
      {
        to: "/sell",
        text: "Sell Property",
      },
      {
        to: "/contact",
        text: "Contact",
      },
    ],
  ];

  return <NavGroup groups={groups} />;
};

export default DesktopNav;
