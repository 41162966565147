import { useMemo } from "react";

export type TooltipTheme = "default" | "success" | "warning" | "danger";

const useTooltipTheme = (theme: TooltipTheme = "default") => {
  const selectedTheme = useMemo(() => {
    const themes = {
      default: {
        text: "text-white",
        bg: "bg-gray-400",
      },
      success: {
        text: "text-white",
        bg: "bg-teal-600",
      },
      warning: {
        text: "text-black",
        bg: "bg-yellow-500",
      },
      danger: {
        text: "text-white",
        bg: "bg-red-600",
      },
    };

    return themes[theme];
  }, [theme]);

  return selectedTheme;
};

export default useTooltipTheme;
