import React, { InputHTMLAttributes } from "react";

import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: any;
}

const TextField = ({ error, ...props }: TextFieldProps) => {
  return (
    <div className="relative w-full">
      <input
        type="text"
        className="z-0 w-full bg-transparent border-b rounded-none outline-none appearance-none"
        {...props}
      />
      {error ? (
        <div className="absolute left-0 z-10 flex justify-center w-full mt-1">
          <Tooltip theme="danger">
            <Icon icon={faTimes}></Icon> {error}
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};
export default TextField;
