import React, { ReactNode } from "react";
import { animated, config, useSpring } from "react-spring";

export interface BackgroundImageProps {
  src: string;
  children?: ReactNode;
}

const BackgroundImage = ({ src, children }: BackgroundImageProps) => {
  const animation = useSpring({
    opacity: 1,
    transform: "scale(1)",
    from: {
      opacity: 0,
      transform: "scale(1.1)",
    },
    config: config.molasses,
  });

  return (
    <div className="w-full h-full bg-black">
      <animated.div
        className="w-full h-full bg-center bg-no-repeat bg-cover"
        style={{
          ...animation,
          backgroundImage: `url(${src})`,
        }}
      ></animated.div>
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50">
        {children}
      </div>
    </div>
  );
};

export default BackgroundImage;
