import { BackgroundImageProps } from "../BackgroundImage/BackgroundImage";
import React from "react";

export interface HorizontalImageProps extends BackgroundImageProps {}

const HorizontalImage = ({ src }: HorizontalImageProps) => {
  return (
    <div
      className="bg-gray-700 bg-center bg-no-repeat bg-cover"
      style={{
        paddingTop: "62.5%",
        backgroundImage: `url(${src})`,
      }}
    ></div>
  );
};

export default HorizontalImage;
