import Footer from "../Footer";
import React from "react";

const StickyFooter = () => {
  return (
    <div className="absolute bottom-0 w-full py-4">
      <Footer />
    </div>
  );
};

export default StickyFooter;
