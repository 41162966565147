import React from "react";

const SocialNav = () => {
  return (
    <ul className="flex space-x-4 text-xs font-light tracking-widest text-white uppercase opacity-75">
      <li>
        <a
          href="https://www.facebook.com/imaginelandthailand/"
          target="_blank"
          rel="noreferrer"
        >
          Facebook
        </a>
      </li>
    </ul>
  );
};

export default SocialNav;
