import React, { ReactNode } from "react";

import { NavLink } from "react-router-dom";

export interface NavItemProps {
  to: string;
  text: ReactNode;
}

export interface NavProps {
  items: NavItemProps[];
}

const Nav = ({ items }: NavProps) => {
  return (
    <ul className="space-y-4 md:space-y-0 md:flex md:items-center">
      {items.map((item, key) => (
        <li key={key}>
          <NavLink
            to={item.to}
            className="inline-block w-full py-2 text-xs font-light tracking-wider uppercase transition-all duration-300 ease-in border-b border-white border-opacity-25 hover:border-opacity-100 md:px-2 md:border-b-0 md:hover:opacity-50"
            activeClassName="border-opacity-100"
          >
            {item.text}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default Nav;
