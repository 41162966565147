import React, { ReactNode } from "react";
import useTooltipTheme, { TooltipTheme } from "../../hooks/useTooltipTheme";

export interface TooltipProps {
  children: ReactNode;
  theme?: TooltipTheme;
}

const Tooltip = ({ children, theme }: TooltipProps) => {
  const { text, bg } = useTooltipTheme(theme);

  return (
    <div
      className={`${text} ${bg} relative px-4 py-2 my-2 text-xs rounded-full`}
    >
      <div className="absolute top-0 left-0 z-0 flex justify-center w-full">
        <div
          className={`w-2 h-2 transform rotate-45 -translate-y-1/2 ${bg}`}
        ></div>
      </div>
      <div className="relative z-10">{children}</div>
    </div>
  );
};

export default Tooltip;
