import React, { TextareaHTMLAttributes } from "react";

import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
}

const Textarea = ({ error, ...props }: TextareaProps) => {
  return (
    <div className="relative w-full">
      <textarea
        className="z-0 w-full p-4 bg-transparent border border-b border-gray-200 rounded-none outline-none appearance-none focus:outline-none"
        {...props}
      />
      {error ? (
        <div className="absolute left-0 z-10 flex justify-center w-full">
          <Tooltip theme="danger">
            <Icon icon={faTimes}></Icon> {error}
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};

export default Textarea;
