import React from "react";
import Nav, { NavItemProps } from "../Nav/Nav";

export interface NavGroupProps {
  groups: NavItemProps[][];
}

const NavGroup = ({ groups }: NavGroupProps) => {
  return (
    <div className="w-full">
      <ul className="py-4 space-y-12 md:space-y-0 md:flex md:items-center">
        {groups.map((group, key) => (
          <li key={key}>
            <Nav items={group} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavGroup;
