import React from "react";

const GridBackground = () => {
  return (
    <div className="absolute w-full h-full bg-black bg-opacity-50">
      <div
        className="flex justify-around w-full h-full px-12 mx-auto"
        style={{
          opacity: "20%",
        }}
      >
        <div className="w-px h-full bg-white"></div>
        <div className="w-px h-full bg-white"></div>
        <div className="w-px h-full bg-white"></div>
        <div className="w-px h-full bg-white"></div>
        <div className="w-px h-full bg-white"></div>
        <div className="w-px h-full bg-white"></div>
        <div className="w-px h-full bg-white"></div>
        <div className="w-px h-full bg-white"></div>
      </div>
    </div>
  );
};

export default GridBackground;
