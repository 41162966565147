import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { animated, config, useTransition } from "react-spring";

export interface SlideshowProps {
  slides: string[];
  timer?: number;
  children?: ReactNode;
}

const Slideshow = ({ slides, timer = 10, children }: SlideshowProps) => {
  const [current, setCurrent] = useState(0);
  const slide = slides[current];

  // Animations
  const images = useTransition(slide, (item) => item, {
    from: {
      opacity: 0,
      transform: "scale(1.1)",
    },
    enter: {
      opacity: 1,
      transform: "scale(1)",
    },
    leave: {
      opacity: 0,
      transform: "scale(1.1)",
    },
    config: config.molasses,
  });

  // Slideshow controller
  const next = useCallback(
    () =>
      setCurrent((current) =>
        current === slides.length - 1 ? 0 : current + 1
      ),
    [slides.length]
  );

  // Loop thru photos
  useEffect(() => {
    const interval = setInterval(() => next(), timer * 1000);
    return () => clearInterval(interval);
  }, [next, timer]);

  return (
    <div className="relative w-full h-full overflow-hidden">
      {images.map(
        ({ item, props, key }) =>
          item && (
            <animated.div
              className="absolute w-full h-full bg-center bg-no-repeat bg-cover"
              style={{
                ...props,
                backgroundImage: `url(${item})`,
                willChange: "opacity",
              }}
              key={key}
            ></animated.div>
          )
      )}

      {children}
    </div>
  );
};

export default Slideshow;
